/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
:root {
  /** ==================================================================== **/
  /** Create Custom CSS Variables from "sfra bootstrap override" variables **/
  /** ==================================================================== **/
  --color-white: #fff;
  --color-black: #000;
  --color-blue: #0070d2;
  --color-green: #008827;
  --color-primary: #00a1e0;
  --color-red: #c41f40;
  --color-success: var(--color-green);
  --color-danger: var(--color-red);
  --color-light-blue: #b8e5f4;
  --color-grey1: #f9f9f9;
  --color-grey2: #eee;
  --color-grey3: #ccc;
  --color-grey4: #999;
  --color-grey5: #666;
  --color-grey6: #444;
  --color-grey7: #222;
  --color-grey8: #333;
  --color-grey-transparent-1: rgba(0, 0, 0, 0.65);
  --color-grey-transparent-2: rgba(0, 0, 0, 0.25);
  --color-light-gray: var(--color-grey1);
  --color-slightly-darker-gray: var(--color-grey4);
  --color-dark-gray: var(--color-grey6);
  --color-darker-gray: var(--color-grey7);
  --color-horizontal-rule-grey: var(--color-grey3);
  --color-product-number-grey: var(--color-grey3);
  --color-horizontal-border-grey: var(--color-grey4);
  --color-menu-link: var(--color-grey6);
  --color-close-menu-bg: var(--color-grey2);
  --color-link-color: var(--color-dark-gray);
  --color-hr-border-color: var(--color-grey3);
  /** ==================================================================== **/
  /** Define skin named variables here                                    **/
  /** ==================================================================== **/
  --skin-background-color-1: var(--color-gray1);
  --skin-border-color-1: var(--color-white);
  --skin-main-text-color-1: var(--color-gray7);
  --skin-main-text-color-2: var(--color-gray7);
  --skin-menu-color-1: #444;
  --skin-menu-color-1-invert: var(--color-white);
  --skin-menu-color-2: #222;
  --skin-link-color-1: #444;
  --skin-link-color-2: var(--color-blue);
  --skin-primary-color-1: var(--color-primary);
  --skin-primary-color-2: var(--color-primary);
  --skin-primary-color-invert-1: var(--color-white);
  --skin-selectbox-background-color-1: var(--color-white);
  --skin-selectbox-text-color-1: var(--skin-main-text-color-1);
  --skin-banner-background-color-1: #444;
  --skin-banner-background-color-2: #222;
  --skin-banner-text-color-1: var(--color-white);
  --skin-heading-color-1: var(--color-primary);
  --skin-heading-color-1-invert: var(--color-white);
  --skin-price-1: var(--color-gray7);
  --skin-header-font: 'Dosis';
  --skin-body-font: var(--font-family-sans-serif); }

:root {
  --color-white: #fff;
  --color-black: #000;
  --color-blue: #0070d2;
  --color-backup-light-blue: #e0eff9;
  --color-green: #008827;
  --color-primary: #004987;
  --color-secondary: #38a6c4;
  --color-accent: #f1f9fc;
  --color-secondary-accent: #fff;
  --color-red: #c41f40;
  --color-success: var(--color-green);
  --color-danger: var(--color-red);
  --color-light-blue: #b8e5f4;
  --color-light-green: #88c290;
  --color-gal-grey: #5e656b;
  --color-backup-darkblue:#001E62;
  --color-backup-brightblue:#0071CE;
  --color-backup-white:#FFFFFF;
  --color-drkblue: #001E62;
  --color-brightblue: #0071CE;
  --color-mediumgrey: #6E6E6E;
  --color-darkgrey:#4A4A4A;
  --color-grey1: #f9f9f9;
  --color-grey2: #eee;
  --color-grey3: #ccc;
  --color-grey4: #999;
  --color-grey5: #666;
  --color-grey6: #444;
  --color-grey7: #222;
  --color-grey8: #333;
  --color-grey-transparent-1: rgba(0, 0, 0, 0.65);
  --color-grey-transparent-2: rgba(0, 0, 0, 0.25);
  --color-light-gray: var(--color-grey1);
  --color-slightly-darker-gray: var(--color-grey4);
  --color-dark-gray: var(--color-grey6);
  --color-darker-gray: var(--color-grey7);
  --color-horizontal-rule-grey: var(--color-grey3);
  --color-product-number-grey: var(--color-grey3);
  --color-horizontal-border-grey: var(--color-grey4);
  --color-menu-link: var(--color-grey6);
  --color-close-menu-bg: var(--color-grey2);
  --color-link-color: var(--color-dark-gray);
  --color-hr-border-color: #e6e6e6;
  --util-box-shadow: 0 0 7px 0 rgb(119 119 119 / 50%);
  --util-box-shadow-active: 0 0 7px 0 rgb(119 119 119);
  /** ==================================================================== **/
  /** Define skin named variables here                                    **/
  /** ==================================================================== **/
  --skin-background-color-1: var(--color-white);
  --skin-border-color-1: var(--color-white);
  --skin-box-shadow: var(--util-box-shadow);
  --skin-box-shadow-active: var(--util-box-shadow-active);
  --skin-main-text-color-1: var(--color-grey7);
  --skin-main-text-color-2: var(--color-grey7);
  --skin-menu-color-1: var(--color-white);
  --skin-menu-color-1-invert: var(--color-primary);
  --skin-menu-color-2: var(--color-secondary);
  --skin-link-color-1: var(--color-primary);
  --skin-link-color-2: var(--color-secondary);
  --skin-primary-color: var(--color-primary);
  --skin-primary-color-invert: var(--color-accent);
  --skin-secondary-color: var(--color-secondary);
  --skin-secondary-color-invert: var(--color-accent);
  --skin-accent-color: var(--color-accent);
  --skin-selectbox-background-color-1: var(--color-white);
  --skin-selectbox-text-color-1: var(--skin-main-text-color-1);
  --skin-banner-background-color-1: var(--color-secondary);
  --skin-banner-background-color-2: var(--color-secondary);
  --skin-banner-text-color-1: var(--color-white);
  --skin-heading-color-1: var(--color-primary);
  --skin-heading-color-1-invert: var(--color-accent);
  --skin-price-1: var(--color-grey7);
  --skin-header-font: BrandonGrotesque-WebRegular, sans-serif;
  --skin-body-font: BrandonGrotesque-WebRegular, sans-serif;
  --skin-promo-color: var(--color-light-green);
  --skin-bg-accent: var(--color-accent);
  --skin-bg-dark: var(--color-secondary); }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0.1em;
  font-family: "BrandonGrotesque-WebRegular";
  line-height: 120%;
  text-transform: uppercase; }

h1.header.page-title,
h1.header.page-title::before {
  background-color: transparent;
  color: var(--color-black); }
  .homepage h1.header.page-title, .homepage
  h1.header.page-title::before {
    background-color: var(--skin-heading-color-1);
    color: var(--skin-heading-color-1-invert); }

a {
  color: var(--skin-link-color-1); }

h1,
.h1 {
  font-size: 1.5rem; }
  @media (min-width: 1024px) {
    h1,
    .h1 {
      font-size: 2.625rem; } }

h2,
.h2 {
  font-size: 1.375rem; }
  @media (min-width: 1024px) {
    h2,
    .h2 {
      font-size: 2.25rem; } }

h3,
.h3 {
  font-size: 1.25rem; }
  @media (min-width: 1024px) {
    h3,
    .h3 {
      font-size: 2rem; } }

h4,
.h4 {
  font-size: 1.125rem; }
  @media (min-width: 1024px) {
    h4,
    .h4 {
      font-size: 1.5rem; } }

h5,
.h5 {
  font-size: 1rem; }
  @media (min-width: 1024px) {
    h5,
    .h5 {
      font-size: 1.375rem; } }

h6,
.h6 {
  font-size: 0.875rem; }
  @media (min-width: 1024px) {
    h6,
    .h6 {
      font-size: 1.125rem; } }

.font-family-BrandonGrotesque-WebItalic,
.font-family-BrandonGrotesque-WebBlackItalic,
.font-family-BrandonGrotesque-WebBoldItalic,
.font-family-BrandonGrotesque-WebLightItalic,
.font-family-BrandonGrotesque-WebMediumItalic,
.font-family-BrandonGrotesque-WebThinItalic {
  font-style: italic; }

.font-family-BrandonGrotesque-WebBlack,
.font-family-BrandonGrotesque-WebBlackItalic {
  font-weight: 900; }

.font-family-BrandonGrotesque-WebBold,
.font-family-BrandonGrotesque-WebBoldItalic {
  font-weight: 800; }

.font-family-BrandonGrotesque-WebLight,
.font-family-BrandonGrotesque-WebLightItalic,
.font-family-BrandonGrotesque-WebThin,
.font-family-BrandonGrotesque-WebThinItalic {
  font-weight: 300; }

.font-family-BrandonGrotesque-WebMedium,
.font-family-BrandonGrotesque-WebMediumItalic {
  font-weight: 500; }

p.small {
  font-size: 0.75rem;
  line-height: 18px; }

b, strong {
  font-family: "BrandonGrotesque-WebBold"; }

.navbar,
.navbar .nav-item,
.dropdown-menu a,
.navbar .nav-item a,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a,
.dropdown-menu {
  color: #004987; }

header .header-main {
  background-color: #fff;
  height: 100%;
  min-height: 76px; }

.is-sticky header {
  position: fixed;
  top: 0;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: justify;
      justify-content: space-between;
  z-index: 99;
  transition: top 0.5s ease-in, height 0.5s 0.5s ease-in-out;
  background-color: #fff; }

.is-scrolled header {
  box-shadow: var(--util-box-shadow); }

@media (min-width: 1024px) {
  header .main-menu {
    background-color: transparent; } }

header .minicart {
  background-color: transparent; }
  @media (min-width: 1024px) {
    header .minicart {
      background-color: var(--color-primary); } }
  header .minicart .minicart-quantity {
    color: var(--skin-secondary-color); }
    @media (min-width: 1024px) {
      header .minicart .minicart-quantity {
        color: var(--color-white); } }

header .navbar-header {
  color: #004987;
  background-color: #e0eff9; }
  header .navbar-header .user .popover {
    background-color: var(--color-grey2); }
    header .navbar-header .user .popover a {
      color: var(--skin-secondary-color); }
  header .navbar-header .country-selector .btn {
    color: #004987;
    font-family: "BrandonGrotesque-WebMedium";
    letter-spacing: 0; }
  header .navbar-header .country-selector a {
    color: #004987; }
  header .navbar-header .country-selector .dropdown-menu {
    border: 1px solid #cdd8e6;
    top: 30px; }
  header .navbar-header .dropdown-country-selector {
    background-color: var(--color-grey2); }

header .navbar,
header .navbar .nav-item,
header .navbar .nav-item a,
header .navbar .nav-item.dropdown,
header .navbar .nav-item.dropdown a,
header .dropdown-menu,
header .dropdown-menu a {
  color: #004987; }

header .nav-item .nav-link {
  background-color: transparent;
  color: var(--skin-secondary-color); }
  @media (min-width: 1024px) {
    header .nav-item .nav-link {
      border-bottom: 0.3125rem solid transparent;
      border-top: 0.3125rem solid transparent; } }

header .nav-item .nav-link:hover,
header .nav-item .nav-link:focus,
header .nav-item.show .nav-link {
  background-color: transparent;
  color: #004987 !important; }

.header_Redesign .header-main {
  min-height: 0;
  height: 96px;
  padding: 0px 25px !important;
  border-bottom: 1px solid #E6E6E6;
  position: fixed;
  top: 40px;
  z-index: 98;
  max-width: 100vw;
  box-sizing: border-box; }
  @media (min-width: 1024px) {
    .header_Redesign .header-main {
      z-index: 96; } }

.header_Redesign .dropdown-menu,
.header_Redesign .dropdown-menu a {
  color: var(--color-drkblue); }

.header_Redesign .navbar-header {
  background-color: var(--color-backup-darkblue); }

@media (max-width: 768px) {
  .header_Redesign .header-main {
    height: 64px;
    padding: 0px 16px !important; } }

footer {
  background-color: var(--skin-accent-color);
  color: var(--color-white); }
  footer h2 {
    font-family: "BrandonGrotesque-WebRegular", sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: var(--color-white); }
    @media (min-width: 769px) {
      footer h2 {
        color: var(--skin-secondary-color); } }
  footer a {
    color: var(--color-white); }
  footer .store a:hover {
    color: var(--color-white); }
    @media (min-width: 769px) {
      footer .store a:hover {
        color: var(--skin-secondary-color); } }
  footer .container div.collapsible-xs button.title {
    color: var(--color-white); }
    @media (min-width: 769px) {
      footer .container div.collapsible-xs button.title {
        color: var(--skin-secondary-color); } }
  footer .back-to-top i.fa-circle {
    color: var(--color-grey1); }

.cetaphil_redesign .footer-newsletter {
  background-color: var(--color-backup-darkblue) !important;
  display: none; }

.cetaphil_redesign footer {
  background-color: var(--color-backup-darkblue) !important;
  padding: 40px 25px;
  background: 0; }
  .cetaphil_redesign footer .store .content {
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 24px;
    font-size: 16px;
    font-family: "Lateral-StandardRegular" !important;
    clear: both;
    display: block; }
    @media (max-width: 768.98px) {
      .cetaphil_redesign footer .store .content {
        line-height: 21px;
        font-size: 14px; } }
  .cetaphil_redesign footer .menu-footer h2,
  .cetaphil_redesign footer .footer-item h2.title,
  .cetaphil_redesign footer .container .footer-container .footer-item .title {
    line-height: 22px !important;
    font-size: 18px !important;
    font-family: "Lateral-CondensedBold", sans-serif !important; }
    @media (max-width: 768.98px) {
      .cetaphil_redesign footer .menu-footer h2,
      .cetaphil_redesign footer .footer-item h2.title,
      .cetaphil_redesign footer .container .footer-container .footer-item .title {
        line-height: 14px !important;
        font-size: 14px !important; } }
  @media (max-width: 768.98px) {
    .cetaphil_redesign footer .menu-footer h2 {
      margin-bottom: 8px; } }
  @media (max-width: 768.98px) {
    .cetaphil_redesign footer .container .footer-container .footer-item .title {
      padding: 16px 0; } }
  .cetaphil_redesign footer .menu-footer li {
    margin-bottom: 10px; }
    @media (max-width: 768.98px) {
      .cetaphil_redesign footer .menu-footer li {
        margin-bottom: 16px; } }
    .cetaphil_redesign footer .menu-footer li a {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: normal;
      font-family: "Lateral-StandardRegular" !important; }
      @media (max-width: 768.98px) {
        .cetaphil_redesign footer .menu-footer li a {
          font-size: 14px;
          line-height: 16px; } }
  .cetaphil_redesign footer .copyright,
  .cetaphil_redesign footer .postscript {
    font-family: "Lateral-StandardRegular" !important;
    line-height: 21px !important;
    font-size: 14px !important; }
    @media (max-width: 768.98px) {
      .cetaphil_redesign footer .copyright,
      .cetaphil_redesign footer .postscript {
        line-height: 19px !important;
        font-size: 13px !important;
        display: inline; } }
  .cetaphil_redesign footer .footer-bottom {
    padding-top: 24px;
    padding-bottom: 0 !important;
    /*:first-child{
                @include media-breakpoint-down(sm){
                    display:none;
                    margin: 0;
                }  
            }*/ }
    @media (max-width: 768.98px) {
      .cetaphil_redesign footer .footer-bottom {
        padding-top: 0; } }
  .cetaphil_redesign footer .social-icons {
    position: absolute;
    bottom: 8%; }
    @media (max-width: 1023.98px) {
      .cetaphil_redesign footer .social-icons {
        position: relative;
        margin: 24px 0 20px; } }
  @media (max-width: 768.98px) {
    .cetaphil_redesign footer .social-links {
      -ms-flex-pack: start;
          justify-content: flex-start; } }
  @media (max-width: 1199.98px) {
    .cetaphil_redesign footer .social-links {
      -ms-flex-pack: start;
          justify-content: flex-start; } }
  .cetaphil_redesign footer .footer_location {
    float: left;
    margin-right: 6px; }
  .cetaphil_redesign footer .footer_location::before {
    background-image: url(../../images/location.png) !important;
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    content: " ";
    float: left;
    margin-top: 0; }
    @media (max-width: 768.98px) {
      .cetaphil_redesign footer .footer_location::before {
        margin-top: -4px; } }
    @media (max-width: 1023.98px) {
      .cetaphil_redesign footer .footer_location::before {
        margin-top: -3px; } }
  .cetaphil_redesign footer .socialbtn {
    float: left;
    padding: 0px;
    margin-right: 16px;
    min-height: 32px;
    min-width: 32px; }
    .cetaphil_redesign footer .socialbtn a {
      width: 100%;
      height: 100%;
      position: absolute;
      text-decoration: none; }
  .cetaphil_redesign footer .social-facebook {
    background-image: url(../../images/facebook.svg) !important; }
  .cetaphil_redesign footer .social-youtube {
    background-image: url(../../images/youtube.svg) !important;
    margin-top: 7px; }
  .cetaphil_redesign footer .social-instagram {
    background-image: url(../../images/instagram.svg) !important; }
  .cetaphil_redesign footer .footer-brand-logo {
    margin: 0 0 40px !important; }
  @media (min-width: 1024px) {
    .cetaphil_redesign footer .footer-container.footer-main-content {
      margin: 0 0 40px !important; } }

@media (max-width: 1023.98px) {
  .cetaphil_redesign footer {
    padding: 32px 1px; } }

@media (min-width: 1024px) {
  .cetaphil_redesign .content-asset {
    padding-right: 40px !important; } }

.suggestions-inner-wrapper {
  overflow: auto; }

.refinements .refinement {
  border: 0; }

.refinements ul li button {
  color: var(--skin-secondary-color); }

.refinements ul li.size-attribute button {
  border: 1px solid var(--color-grey5);
  background-color: var(--color-white); }
  .refinements ul li.size-attribute button.selected {
    border: 1px solid var(--skin-secondary-color);
    background-color: var(--skin-secondary-color);
    color: var(--color-white); }

.refinements.panel {
  padding: 0 10px; }
  @media (min-width: 1024px) {
    .refinements.panel {
      padding: 0; } }
  @media (min-width: 1024px) {
    .refinements.panel .panel-heading {
      margin-top: 30px; } }
  .refinements.panel .panel-heading .card-header {
    border-bottom: 1px solid #cdd8e6;
    padding: 0 0 10px 0; }
    .refinements.panel .panel-heading .card-header a {
      display: block;
      position: relative; }
      .refinements.panel .panel-heading .card-header a.collapsed::after {
        transform: rotate(45deg); }
      .refinements.panel .panel-heading .card-header a.collapsed::before {
        transform: rotate(-45deg); }
      .refinements.panel .panel-heading .card-header a::after, .refinements.panel .panel-heading .card-header a::before {
        color: #004987;
        content: "";
        display: block;
        border-bottom: 2px solid;
        margin-right: 0;
        position: absolute;
        top: 50%;
        width: 8px; }
      .refinements.panel .panel-heading .card-header a::after {
        border: 1px solid;
        right: 10px;
        transform: rotate(-45deg); }
      .refinements.panel .panel-heading .card-header a::before {
        right: 5px;
        transform: rotate(45deg); }
    .refinements.panel .panel-heading .card-header button.title {
      color: #5e656b;
      font-family: "BrandonGrotesque-WebRegular";
      font-size: 18px;
      line-height: 120%;
      letter-spacing: 0.01em;
      text-transform: capitalize; }
  .refinements.panel .panel-heading h2 {
    color: #5e656b;
    font-family: "BrandonGrotesque-WebRegular";
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.01em;
    text-transform: capitalize; }
  .refinements.panel .panel-body {
    padding-bottom: 15px; }
    .refinements.panel .panel-body ul {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      margin: 0;
      padding: 0; }
      .refinements.panel .panel-body ul > li > button,
      .refinements.panel .panel-body ul > li ul > li > button {
        color: #5e656b;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        font-family: "BrandonGrotesque-WebLight";
        font-size: 16px;
        margin-bottom: 15px;
        padding-left: 0;
        text-transform: capitalize;
        text-align: left; }
        .refinements.panel .panel-body ul > li > button .fa,
        .refinements.panel .panel-body ul > li ul > li > button .fa {
          display: inline-block;
          height: 18px;
          margin-right: 10px; }
          .refinements.panel .panel-body ul > li > button .fa.fa-check-circle::after,
          .refinements.panel .panel-body ul > li ul > li > button .fa.fa-check-circle::after {
            border-radius: 100%;
            background-color: #38a6c4;
            content: "";
            display: block;
            height: 9px;
            position: relative;
            left: 3px;
            top: -12px;
            width: 9px; }
          .refinements.panel .panel-body ul > li > button .fa.fa-circle-o::before, .refinements.panel .panel-body ul > li > button .fa.fa-check-circle::before,
          .refinements.panel .panel-body ul > li ul > li > button .fa.fa-circle-o::before,
          .refinements.panel .panel-body ul > li ul > li > button .fa.fa-check-circle::before {
            border: 1px solid #7b7b7b;
            border-radius: 100%;
            content: "";
            display: block;
            height: 15px;
            width: 15px; }
          .refinements.panel .panel-body ul > li > button .fa.fa-square-o::before, .refinements.panel .panel-body ul > li > button .fa.fa-check-square::before,
          .refinements.panel .panel-body ul > li ul > li > button .fa.fa-square-o::before,
          .refinements.panel .panel-body ul > li ul > li > button .fa.fa-check-square::before {
            border: 1px solid #7b7b7b;
            border-radius: 2px;
            content: "";
            display: block;
            height: 15px;
            width: 15px; }
        .refinements.panel .panel-body ul > li > button img.fa-check-square,
        .refinements.panel .panel-body ul > li ul > li > button img.fa-check-square {
          background-color: #38a6c4;
          border: 1px solid #38a6c4;
          border-radius: 2px;
          display: block;
          height: 16px;
          padding: 2px;
          margin-right: 15px;
          width: 16px; }

@media (max-width: 1199.98px) {
  .refinement-wrapper {
    position: relative; } }

.refinement-bar {
  padding: 0 !important;
  top: 250px;
  box-shadow: unset !important; }
  @media (max-width: 1199.98px) {
    .refinement-bar {
      z-index: 2021;
      max-height: 70%;
      overflow-y: scroll; } }
  @media (min-width: 1024px) {
    .refinement-bar {
      display: block !important;
      position: static;
      box-shadow: none; } }
  .refinement-bar .panel-group {
    border-bottom: 1px solid #cdd8e6;
    padding: 0 10px 23px; }
    @media (min-width: 769px) {
      .refinement-bar .panel-group {
        border-bottom: none; } }
  .refinement-bar .apply-button {
    font-size: 13px;
    margin: 0 auto;
    margin-top: 13px;
    margin-bottom: 13px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    padding: 0.75rem 1.5rem;
    opacity: 1;
    float: unset;
    font-family: "BrandonGrotesque-WebRegular";
    width: 288px; }
    @media (min-width: 1200px) {
      .refinement-bar .apply-button {
        display: none; } }

.animate {
  transition: 1s;
  opacity: 0;
  display: block;
  position: absolute;
  z-index: 0; }
  .animate ~ div {
    padding: 0 10px; }
  .filter-active .animate {
    opacity: 1;
    position: static; }
    @media (max-width: 1199.98px) {
      .filter-active .animate {
        position: absolute;
        overflow-y: scroll;
        top: 0;
        z-index: 2;
        min-height: 100%; } }
  @media (min-width: 1024px) {
    .animate {
      opacity: 1;
      position: static; } }

@media (min-width: 769px) {
  .grid-tile-wrapper:hover {
    box-shadow: var(--skin-box-shadow);
    z-index: 2; } }

.search-results select.sort-order-menu {
  max-width: 202px; }
  .search-results select.sort-order-menu:focus-within {
    border-color: #a4d600; }
  .search-results select.sort-order-menu:focus-visible {
    border: 1px solid #a4d600;
    outline: none;
    box-shadow: none; }

.search-results .filter-bar,
.search-results .filter-bar-line {
  border-top: 0;
  border-bottom: 1px solid #e0eff9;
  margin-top: 20px;
  padding-top: 0;
  -ms-flex-order: 4;
      order: 4; }
  @media (min-width: 1024px) {
    .search-results .filter-bar,
    .search-results .filter-bar-line {
      border-top: 0;
      border-bottom: 0;
      margin-top: 0;
      padding-top: 0;
      -ms-flex-order: 2;
          order: 2; } }
  .search-results .filter-bar .filter-value,
  .search-results .filter-bar-line .filter-value {
    background-color: #004987;
    border-radius: 4px;
    padding: 8px; }
    .search-results .filter-bar .filter-value:not(:last-child),
    .search-results .filter-bar-line .filter-value:not(:last-child) {
      margin-bottom: 10px; }
    .search-results .filter-bar .filter-value:last-child,
    .search-results .filter-bar-line .filter-value:last-child {
      margin-bottom: 16px; }
      @media (min-width: 1024px) {
        .search-results .filter-bar .filter-value:last-child,
        .search-results .filter-bar-line .filter-value:last-child {
          margin-bottom: 0; } }
    .search-results .filter-bar .filter-value button,
    .search-results .filter-bar-line .filter-value button {
      color: #fff;
      font-family: "BrandonGrotesque-WebRegular";
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      position: relative;
      text-transform: capitalize; }
      .search-results .filter-bar .filter-value button::after, .search-results .filter-bar .filter-value button::before,
      .search-results .filter-bar-line .filter-value button::after,
      .search-results .filter-bar-line .filter-value button::before {
        background-image: none;
        border-bottom: 2px solid #fff;
        content: "";
        display: block;
        position: absolute;
        bottom: 45%;
        right: 0;
        width: 15px; }
      .search-results .filter-bar .filter-value button::after,
      .search-results .filter-bar-line .filter-value button::after {
        border: 1px solid #fff;
        transform: rotate(45deg);
        top: inherit; }
      .search-results .filter-bar .filter-value button::before,
      .search-results .filter-bar-line .filter-value button::before {
        transform: rotate(-45deg); }
  .search-results .filter-bar ul,
  .search-results .filter-bar-line ul {
    padding: 0; }
  .search-results .filter-bar .reset,
  .search-results .filter-bar-line .reset {
    text-decoration: underline;
    text-transform: capitalize;
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: 150%;
    font-family: "BrandonGrotesque-WebRegular";
    padding: 5px 15px 0; }

.search-results .filter-header {
  width: 100%;
  margin: 0 auto; }
  .search-results .filter-header .header-bar {
    background-color: #fff;
    border-bottom: 1px solid #e0eff9;
    padding: 20px 15px; }
    .search-results .filter-header .header-bar .reset {
      text-decoration: underline;
      text-transform: capitalize;
      font-size: 16px;
      font-style: normal;
      font-weight: 100;
      line-height: 150%;
      font-family: "BrandonGrotesque-WebRegular";
      position: relative;
      left: 130px; }
      @media (max-width: 768.98px) {
        .search-results .filter-header .header-bar .reset {
          display: none; } }
    .search-results .filter-header .header-bar button.close {
      color: #004987;
      font-family: "BrandonGrotesque-WebRegular";
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      position: relative;
      text-transform: capitalize; }
      .search-results .filter-header .header-bar button.close .fa-close::after, .search-results .filter-header .header-bar button.close .fa-close::before {
        background-image: none;
        border-bottom: 2px solid #004987;
        content: "";
        display: block;
        position: absolute;
        bottom: 45%;
        right: 0;
        width: 20px; }
      .search-results .filter-header .header-bar button.close .fa-close::after {
        border: 1px solid #004987;
        transform: rotate(45deg); }
      .search-results .filter-header .header-bar button.close .fa-close::before {
        transform: rotate(-45deg); }

@media (max-width: 1199.98px) {
  .search-results .filter-results {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    max-width: 150px;
    position: relative; }
    .search-results .filter-results::before {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 7C3 6.44772 3.44772 6 4 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H4C3.44772 8 3 7.55228 3 7ZM5 12C5 12.5523 5.44772 13 6 13H18C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11H6C5.44772 11 5 11.4477 5 12ZM7 17C7 17.5523 7.44772 18 8 18H16C16.5523 18 17 17.5523 17 17C17 16.4477 16.5523 16 16 16H8C7.44772 16 7 16.4477 7 17Z' fill='white'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      content: "";
      display: block;
      height: 24px;
      margin-right: 10px;
      width: 24px; } }

.search-results .grid-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 0;
  padding: 24px 0 0; }
  @media (min-width: 1024px) {
    .search-results .grid-header {
      border-top: 1px solid #e0eff9;
      border-bottom: 1px solid #e0eff9;
      margin-bottom: 30px;
      padding: 1% 0; } }

.search-results .grid-footer {
  margin-bottom: 30px; }

.search-results .result-count {
  color: #004987;
  font-family: "BrandonGrotesque-WebBold";
  font-size: 16px;
  text-transform: uppercase; }

.search-results .secondary-bar {
  padding: 20px 10px; }
  @media (min-width: 1024px) {
    .search-results .secondary-bar {
      padding: 0; } }

.search-results .sort-by-label {
  color: #5e656b;
  font-family: "BrandonGrotesque-WebBold";
  font-weight: bold;
  font-size: 16px; }

.search-results .sort-order-menu {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.2946 0.99966C10.9053 0.610387 10.2743 0.610043 9.88462 0.998891L6 4.87504L2.11538 0.998891C1.72569 0.610043 1.09466 0.610387 0.705384 0.99966C0.315811 1.38923 0.315811 2.02086 0.705385 2.41043L5.29289 6.99794C5.68342 7.38846 6.31658 7.38846 6.70711 6.99794L11.2946 2.41043C11.6842 2.02086 11.6842 1.38923 11.2946 0.99966Z' fill='%23004987'/%3E%3C/svg%3E");
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  border: 1px solid #cdd8e6;
  border-radius: 8px;
  color: #5e656b;
  font-family: "BrandonGrotesque-WebLight";
  font-weight: normal;
  font-size: 14px;
  display: inline-block;
  margin-left: 0;
  padding: 10px 40px 10px 15px;
  text-transform: capitalize;
  max-width: 150px;
  width: 100%; }
  @media (min-width: 544px) {
    .search-results .sort-order-menu {
      font-size: 16px; } }
  @media (min-width: 1024px) {
    .search-results .sort-order-menu {
      margin-left: 25px; } }

.search-results .product-search {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: start;
      align-items: flex-start;
  padding: 13px 10px;
  position: inherit;
  width: 272px;
  height: auto;
  background: #f1f9fc;
  border-radius: 8px 8px 0 0;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #004987;
  margin-top: 3%; }
  @media (max-width: 768.98px) {
    .search-results .product-search {
      width: 100%; } }

.search-results .content-search {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  padding: 13px 10px;
  position: inherit;
  width: 272px;
  height: auto;
  background: #f1f9fc;
  border-radius: 8px 8px 0 0;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #004987;
  margin: 0 30px 0;
  margin-top: 5%; }
  @media (max-width: 768.98px) {
    .search-results .content-search {
      width: 90%; } }
  .search-results .content-search.active {
    margin-top: 0; }

.m-8 {
  margin: 8px; }

.mx-8 {
  margin-left: 8px;
  margin-right: 8px; }

.my-8 {
  margin-top: 8px;
  margin-bottom: 8px; }

.ml-8 {
  margin-left: 8px; }

.mr-8 {
  margin-right: 8px; }

.mb-8 {
  margin-bottom: 8px; }

.mt-8 {
  margin-top: 8px; }

.p-8 {
  padding: 8px; }

.px-8 {
  padding-left: 8px;
  padding-right: 8px; }

.py-8 {
  padding-top: 8px;
  padding-bottom: 8px; }

.pl-8 {
  padding-left: 8px; }

.pr-8 {
  padding-right: 8px; }

.pb-8 {
  padding-bottom: 8px; }

.pt-8 {
  padding-top: 8px; }

.m-12 {
  margin: 12px; }

.mx-12 {
  margin-left: 12px;
  margin-right: 12px; }

.my-12 {
  margin-top: 12px;
  margin-bottom: 12px; }

.ml-12 {
  margin-left: 12px; }

.mr-12 {
  margin-right: 12px; }

.mb-12 {
  margin-bottom: 12px; }

.mt-12 {
  margin-top: 12px; }

.p-12 {
  padding: 12px; }

.px-12 {
  padding-left: 12px;
  padding-right: 12px; }

.py-12 {
  padding-top: 12px;
  padding-bottom: 12px; }

.pl-12 {
  padding-left: 12px; }

.pr-12 {
  padding-right: 12px; }

.pb-12 {
  padding-bottom: 12px; }

.pt-12 {
  padding-top: 12px; }

.m-16 {
  margin: 16px; }

.mx-16 {
  margin-left: 16px;
  margin-right: 16px; }

.my-16 {
  margin-top: 16px;
  margin-bottom: 16px; }

.ml-16 {
  margin-left: 16px; }

.mr-16 {
  margin-right: 16px; }

.mb-16 {
  margin-bottom: 16px; }

.mt-16 {
  margin-top: 16px; }

.p-16 {
  padding: 16px; }

.px-16 {
  padding-left: 16px;
  padding-right: 16px; }

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px; }

.pl-16 {
  padding-left: 16px; }

.pr-16 {
  padding-right: 16px; }

.pb-16 {
  padding-bottom: 16px; }

.pt-16 {
  padding-top: 16px; }

.m-24 {
  margin: 24px; }

.mx-24 {
  margin-left: 24px;
  margin-right: 24px; }

.my-24 {
  margin-top: 24px;
  margin-bottom: 24px; }

.ml-24 {
  margin-left: 24px; }

.mr-24 {
  margin-right: 24px; }

.mb-24 {
  margin-bottom: 24px; }

.mt-24 {
  margin-top: 24px; }

.p-24 {
  padding: 24px; }

.px-24 {
  padding-left: 24px;
  padding-right: 24px; }

.py-24 {
  padding-top: 24px;
  padding-bottom: 24px; }

.pl-24 {
  padding-left: 24px; }

.pr-24 {
  padding-right: 24px; }

.pb-24 {
  padding-bottom: 24px; }

.pt-24 {
  padding-top: 24px; }

.m-32 {
  margin: 32px; }

.mx-32 {
  margin-left: 32px;
  margin-right: 32px; }

.my-32 {
  margin-top: 32px;
  margin-bottom: 32px; }

.ml-32 {
  margin-left: 32px; }

.mr-32 {
  margin-right: 32px; }

.mb-32 {
  margin-bottom: 32px; }

.mt-32 {
  margin-top: 32px; }

.p-32 {
  padding: 32px; }

.px-32 {
  padding-left: 32px;
  padding-right: 32px; }

.py-32 {
  padding-top: 32px;
  padding-bottom: 32px; }

.pl-32 {
  padding-left: 32px; }

.pr-32 {
  padding-right: 32px; }

.pb-32 {
  padding-bottom: 32px; }

.pt-32 {
  padding-top: 32px; }

.m-48 {
  margin: 48px; }

.mx-48 {
  margin-left: 48px;
  margin-right: 48px; }

.my-48 {
  margin-top: 48px;
  margin-bottom: 48px; }

.ml-48 {
  margin-left: 48px; }

.mr-48 {
  margin-right: 48px; }

.mb-48 {
  margin-bottom: 48px; }

.mt-48 {
  margin-top: 48px; }

.p-48 {
  padding: 48px; }

.px-48 {
  padding-left: 48px;
  padding-right: 48px; }

.py-48 {
  padding-top: 48px;
  padding-bottom: 48px; }

.pl-48 {
  padding-left: 48px; }

.pr-48 {
  padding-right: 48px; }

.pb-48 {
  padding-bottom: 48px; }

.pt-48 {
  padding-top: 48px; }

.m-64 {
  margin: 64px; }

.mx-64 {
  margin-left: 64px;
  margin-right: 64px; }

.my-64 {
  margin-top: 64px;
  margin-bottom: 64px; }

.ml-64 {
  margin-left: 64px; }

.mr-64 {
  margin-right: 64px; }

.mb-64 {
  margin-bottom: 64px; }

.mt-64 {
  margin-top: 64px; }

.p-64 {
  padding: 64px; }

.px-64 {
  padding-left: 64px;
  padding-right: 64px; }

.py-64 {
  padding-top: 64px;
  padding-bottom: 64px; }

.pl-64 {
  padding-left: 64px; }

.pr-64 {
  padding-right: 64px; }

.pb-64 {
  padding-bottom: 64px; }

.pt-64 {
  padding-top: 64px; }

.m-72 {
  margin: 72px; }

.mx-72 {
  margin-left: 72px;
  margin-right: 72px; }

.my-72 {
  margin-top: 72px;
  margin-bottom: 72px; }

.ml-72 {
  margin-left: 72px; }

.mr-72 {
  margin-right: 72px; }

.mb-72 {
  margin-bottom: 72px; }

.mt-72 {
  margin-top: 72px; }

.p-72 {
  padding: 72px; }

.px-72 {
  padding-left: 72px;
  padding-right: 72px; }

.py-72 {
  padding-top: 72px;
  padding-bottom: 72px; }

.pl-72 {
  padding-left: 72px; }

.pr-72 {
  padding-right: 72px; }

.pb-72 {
  padding-bottom: 72px; }

.pt-72 {
  padding-top: 72px; }

.btn {
  border-radius: 2.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 0.6875rem;
  font-family: "BrandonGrotesque-WebBold";
  line-height: 130%;
  letter-spacing: 1px; }
  @media (min-width: 1024px) {
    .btn {
      font-size: 0.8125rem; } }

.i-ml-12 {
  margin-left: 12px;
  position: relative; }

.i-mr-12 {
  margin-right: 12px; }

.btn-primary {
  background: linear-gradient(105.49deg, #004987 47.63%, #38a6c4 93.7%);
  border-color: var(--skin-primary-color-1);
  border: 1px solid;
  color: var(--skin-primary-color-invert-1); }

.btn-outline-primary {
  color: var(--skin-primary-color-1);
  border-radius: 100rem;
  border: solid 1px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #004987, #38a6c4);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset; }

.btn-link {
  font-size: 0.8125rem;
  letter-spacing: 1px;
  color: #004987;
  font-family: "BrandonGrotesque-WebBold";
  border-radius: 3px; }
  .btn-link:hover {
    color: var(--skin-secondary-color);
    text-decoration: none; }
  .btn-link:focus {
    box-shadow: 0 0 0 0.2rem #a1d400; }

.btn-primary:hover,
.btn-outline-primary:hover {
  border-color: var(--skin-secondary-color);
  background: var(--skin-secondary-color);
  color: #fff;
  box-shadow: none; }

.btn-primary.disabled, .btn-primary:disabled,
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  border-color: #c3c3c3;
  color: #5e656b;
  background: #c3c3c3;
  box-shadow: none; }

.btn-primary:focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem #a1d400;
  color: #fff; }

.btn-outline-white {
  border: 1px solid #fff;
  color: #fff;
  border-radius: 40px; }
  .btn-outline-white:hover {
    border-color: #fff;
    background: transparent;
    color: #fff;
    box-shadow: none; }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    border-color: #c3c3c3;
    color: #5e656b;
    background: #c3c3c3;
    box-shadow: none; }
  .btn-outline-white:focus {
    box-shadow: 0 0 0 0.2rem #a1d400;
    color: #fff; }

.btn-circle-icon {
  background: #fff;
  height: 4.375rem;
  width: 4.375rem;
  border-radius: 4.375rem;
  text-align: center;
  padding: 0;
  font-size: 1.75rem;
  letter-spacing: 0; }
  .btn-circle-icon i {
    color: #fff;
    position: relative;
    left: 4px;
    top: 2px; }
    .btn-circle-icon i::before {
      background: linear-gradient(105.49deg, #004987 47.63%, #38a6c4 93.7%);
      display: initial;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; }
  .btn-circle-icon:hover {
    border-color: var(--skin-secondary-color);
    background: var(--skin-secondary-color);
    color: #fff;
    box-shadow: none; }
    .btn-circle-icon:hover i::before {
      color: #fff;
      -webkit-text-fill-color: #fff; }
  .btn-circle-icon:focus {
    box-shadow: 0 0 0 0.2rem #a1d400; }

.page-redesign .btn-link, .cetaphil_redesign .btn-link {
  font-family: "Lateral-CondensedBold"; }

.page-redesign .btn .icon, .cetaphil_redesign .btn .icon {
  position: relative;
  left: 2px;
  top: -1px; }

.page-redesign .btn.btn-primary-revert, .cetaphil_redesign .btn.btn-primary-revert {
  background: #001E62;
  color: #fff;
  border-color: #fff;
  border: 1px solid; }
  .page-redesign .btn.btn-primary-revert:hover, .cetaphil_redesign .btn.btn-primary-revert:hover {
    color: #001E62;
    background: #fff;
    border-color: #001E62; }

.page-redesign .btn.btn-primary, .cetaphil_redesign .btn.btn-primary {
  background: #001E62;
  color: #fff;
  border-color: #001E62; }
  .page-redesign .btn.btn-primary:hover, .cetaphil_redesign .btn.btn-primary:hover {
    color: #001E62;
    background: #fff;
    border-color: #001E62; }

.page-redesign .btn.btn-outline-primary, .cetaphil_redesign .btn.btn-outline-primary {
  color: #001E62;
  background: #fff;
  border-color: #001E62; }
  .page-redesign .btn.btn-outline-primary:hover, .cetaphil_redesign .btn.btn-outline-primary:hover {
    background: #001E62;
    color: #fff;
    border-color: #001E62; }

.page-redesign .btn.btn-secondary, .cetaphil_redesign .btn.btn-secondary {
  color: #fff;
  background: #001E62;
  border-color: #001E62; }
  .page-redesign .btn.btn-secondary:hover, .cetaphil_redesign .btn.btn-secondary:hover {
    background: #048EFF;
    color: #fff;
    border-color: #048EFF; }

.page-redesign .btn.btn-outline-secondary, .cetaphil_redesign .btn.btn-outline-secondary {
  color: #fff;
  background: #048EFF;
  border-color: #048EFF; }
  .page-redesign .btn.btn-outline-secondary:hover, .cetaphil_redesign .btn.btn-outline-secondary:hover {
    background: #001E62;
    color: #fff;
    border-color: #001E62; }

.page-redesign .btn.btn-outline-white, .cetaphil_redesign .btn.btn-outline-white {
  color: #001E62;
  background: #F4F4F4;
  border-color: #001E62; }
  .page-redesign .btn.btn-outline-white:hover, .cetaphil_redesign .btn.btn-outline-white:hover {
    background: #001E62;
    color: #fff;
    border-color: #001E62; }

body,
.card,
.card-header,
.hero {
  background-color: var(--skin-background-color-1);
  font-family: var(--skin-body-font);
  color: var(--skin-main-text-color-1); }

a {
  color: var(--skin-link-color-1); }

.hero {
  border-bottom-color: var(--skin-background-color-1); }

.main-menu {
  background-color: var(--skin-banner-background-color-1); }

.banner-color {
  background-color: var(--skin-banner-background-color-1); }

.header-banner {
  background: linear-gradient(105.49deg, #004987 47.63%, #38a6c4 93.7%);
  color: var(--skin-banner-text-color-1); }

h1.header.page-title,
h1.header.page-title::before {
  background-color: var(--skin-heading-color-1);
  color: var(--skin-heading-color-1-invert); }

.grid-tile-wrapper .product {
  height: 100%; }

.product-tile {
  background-color: transparent;
  border: 10px solid transparent;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: stretch;
      align-items: stretch;
  -ms-flex-pack: stretch;
      justify-content: stretch;
  height: 100%;
  margin: 0;
  padding: 0; }

.refinements ul li button {
  color: var(--skin-primary-color-1); }

.custom-select {
  background-color: var(--skin-selectbox-background-color-1);
  color: var(--skin-selectbox-text-color-1); }

.navbar,
.navbar .nav-item,
.dropdown-menu a,
.navbar .nav-item a,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a,
.dropdown-menu {
  color: var(--skin-menu-color-1); }

header ~ #maincontent .container a:not(.btn-primary, .btn-outline-primary) {
  color: var(--skin-primary-color-1); }

.price {
  color: var(--skin-price-1); }

.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown .dropdown-menu.show {
  color: var(--skin-selectbox-text-color-1); }

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
  background-color: var(--skin-menu-color-1-invert);
  color: var(--skin-menu-color-1) !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "BrandonGrotesque-WebMedium", sans-serif; }

.brand-primary {
  color: #004987; }

.brand-secondary {
  color: #38a6c4; }

p {
  margin-bottom: 0; }

@media (min-width: 1024px) and (max-width: 1399.98px) {
  .apply-button.btn.btn-primary.close {
    display: none; } }

.refinements.panel .panel-body ul > li > button > span {
  text-transform: none; }
